<template>
  <div class="flex flex-col gap-1">
    <div class="flex flex-col p-5 gap-4">
      <div class="flex justify-between">
        <div class="text-xl font-anomaliaBold text-secondary-900">
          {{ eventTitle() }} {{ user.fullName }}
        </div>
        <PVButton class="h-[33px]" @click="openMyMentorship">
          <div class="flex items-center gap-1">
            <span class="text-sm font-simplerBold h-3.5">
              {{ $t('course_page.my_mentorship') }}
            </span>
            <ArrowLeft :size="14" />
          </div>
        </PVButton>
      </div>

      <div v-if="accountStore.flattenedSurveys.length">
        <div class="text-sm font-simplerBold text-secondary-900 py-3">
          השאלונים שלך
        </div>
        <div class="grid grid-cols-1 xs:grid-cols-2 gap-3">
          <CourseCard
            v-for="{ course, group } in accountStore.flattenedSurveys"
            :key="course.id"
            type="teacher"
            :course="course"
            :group="group"/>
        </div>
      </div>
      <div v-if="accountStore.flattenedCourses.length">
        <div class="text-sm font-simplerBold text-secondary-900 py-3">
          הקורסים שלך
        </div>
        <div class="grid grid-cols-1 xs:grid-cols-2 gap-3">
          <CourseCard
            v-for="{ course, group } in accountStore.flattenedCourses"
            :key="course.id"
            type="teacher"
            :course="course"
            :group="group"/>
        </div>
      </div>
      <div
        v-else
        class="flex items-center justify-center font-anomaliaLight md:text-xl mt-20">
        <div class="flex flex-col text-center">
          <span>עדיין אין לך קורסים,</span>
          <span>כדאי לראות מה יש לנו להציע לך</span>
          <RouterLink
            :to="{ name: 'library' }"
            class="mt-2 bg-primary-200 rounded-xl py-2">
            <span class="text-primary md:text-2xl">בספריה!</span>
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { ArrowLeft } from 'lucide-vue-next';
import { impersonate } from '/@/app/services/accounts';

import { useAccountStore } from '/@/app/store/account';
import { CourseCard } from '@amit/components';
import { useNavigation } from '@amit/layout';

import PVButton from 'primevue/button';

const accountStore = useAccountStore();
const { t } = useI18n();
const d = new Date();

const user = computed(() => accountStore.user);

const eventTitle = () => {
  if (d.getHours() > 5 && d.getHours() <= 12) {
    return t('dashboard.goodMorning');
  } else if (d.getHours() > 12 && d.getHours() <= 15) {
    return t('dashboard.goodNoon');
  } else if (d.getHours() > 15 && d.getHours() <= 17) {
    return t('dashboard.goodAfterNoon');
  } else if (d.getHours() > 17 && d.getHours() <= 22) {
    return t('dashboard.erevTov');
  } else return t('dashboard.goodNight');
};

useNavigation(
  computed({
    get() {
      return accountStore.courseLinks;
    },
    set() {},
  }),
);

const openMyMentorship = () => {
  const { onSuccess } = impersonate(accountStore.user.id);
  onSuccess(({ data }) => {
    const { token } = data;
    if (token) {
      window.open(
        `${import.meta.env.VITE_STUDENTS_BASE}?token=${token}`,
        '_blank',
      );
    }
  });
};
</script>
